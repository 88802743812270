(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name wbOrder.constant:wallets
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .constant('wallets', {
      RECREATION: {
        id: 0,
        field: 'loadAmountRecreation',
        name: 'order.multiWallet.recreation.title',
        icon: 'images/wallets/recreation.svg'
      },
      MEAL: {
        id: 1,
        field: 'loadAmountMeal',
        name: 'order.multiWallet.meal.title',
        icon: 'images/wallets/meal.svg'
      },
      ACCOMODATION: {
        id: 2,
        field: 'loadAmountAccommodation',
        name: 'order.multiWallet.accommodation.title',
        icon: 'images/wallets/accommodation.svg'
      }
    });
}());
